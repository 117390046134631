<template>
	<div class="order">
		<el-tabs v-model="activeName" @tab-click="tabClick">
		    <el-tab-pane label="全部" name="0">
				<order-list :data="allList" :total="total" 
				:pageSize="pageSize" @order-chang-page="changePage" 
				@del-order="delOrder" @chose-check="choseCheck" 
				@goto-pay="gotoPay" @cancel-order="cancelOrder" 
				@show-fefund="showfefund" @cancel-fefund="cancelfefund">
				</order-list>
			</el-tab-pane>
		    <el-tab-pane label="已完成" name="3">
				<order-list :data="completedList" :total="total" 
				:pageSize="pageSize" @order-chang-page="changePage" 
				@del-order="delOrder" @chose-check="choseCheck" 
				@goto-pay="gotoPay" @cancel-order="cancelOrder" 
				@show-fefund="showfefund" @cancel-fefund="cancelfefund">
				</order-list>
			</el-tab-pane>
		    <el-tab-pane label="未支付" name="1">
				<order-list :data="incompleteList" :total="total" 
				:pageSize="pageSize" @order-chang-page="changePage" 
				@del-order="delOrder" @chose-check="choseCheck" 
				@goto-pay="gotoPay" @cancel-order="cancelOrder" 
				@show-fefund="showfefund" @cancel-fefund="cancelfefund">
				</order-list>
			</el-tab-pane>
			<el-tab-pane label="已取消" name="2">
				<order-list :data="cancelledList" :total="total" 
				:pageSize="pageSize" @order-chang-page="changePage" 
				@del-order="delOrder" @chose-check="choseCheck" 
				@goto-pay="gotoPay" @cancel-order="cancelOrder" 
				@show-fefund="showfefund" @cancel-fefund="cancelfefund">
				</order-list>
			</el-tab-pane>
		</el-tabs>
		<el-popconfirm
			title="确定要删除选中的订单吗？"
			@confirm="batchDelete"
		>
			<div class="batchDelete" slot="reference">批量删除</div>
		</el-popconfirm>
		<el-dialog
		  title="退款申请"
		  :visible.sync="fefundVisible"
		  width="600px"
		  custom-class="fefundDialog"
		  :close-on-click-modal="false"
		  :close-on-press-escape="false">
		  <el-form :model="fefundForm" ref="fefundForm" label-width="110px" class="fefundForm">
		  	<el-form-item label="退款原因" class="is-required onlyInput" :class="{'is-error':orde_refund_dcid}">
		  		<el-select v-model="fefundForm.orde_refund_dcid" placeholder="请选择退款原因" class="refundSelect" @change="changeRefund">
		  		    <el-option
		  		      v-for="(item,index) in refundList"
		  		      :key="index"
		  		      :label="item.dic_name"
		  		      :value="item.dic_id">
		  		    </el-option>
		  		</el-select>
				<div class="errorText" v-if="orde_refund_dcid"><i class="el-icon-warning"></i>请选择退款原因</div>
		  	</el-form-item>
		  	<el-form-item label="退款原因描述" class="onlyInput">
		  		<el-input v-model="fefundForm.fu_remark" type="textarea" maxlength="500" class="fu_remark"></el-input>
		  	</el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="fefundVisible = false">取 消</el-button>
		    <el-button type="primary" @click="fefundOk">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js'; 
	import orderList from "/src/components/orderList";//列表页面
	export default {
			components: {
				orderList
			},
			data() {
				return {
					activeName:"0",//选项卡选中项
					allList:[],//全部列表
					completedList:[],//已完成列表
					incompleteList:[],//未完成列表
					cancelledList:[],//已取消列表
					total:0,
					pageSize:5,
					fefundVisible:false,//退款弹窗
					refundList:[],//退款原因列表
					fefundForm:{
						orde_refund_dcid:"",//退款原因
						fu_remark:"",//退款原因描述
					},
					cuurOrder:{},//当前订单
					orde_refund_dcid:false,//退款原因验证
				}
			},
			methods: {
				//获取订单
				getOrder(orde_status,page){
					this.$http.post(this.PublicJs.publicPath + "/api/student.Order/myOrder.html", {
						orde_status:orde_status,
						token:window.sessionStorage.getItem('token'),
						page:page,
						limit:this.pageSize,
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.total = response.data.data.count;
							for(var i=0;i<response.data.data.data.length;i++){
								response.data.data.data[i].check = false;
							}
							if(this.activeName == "0"){
								this.allList = response.data.data.data;
							}else if(this.activeName == "1"){
								this.incompleteList = response.data.data.data;
							}else if(this.activeName == "2"){
								this.cancelledList = response.data.data.data;
							}else if(this.activeName == "3"){
								this.completedList = response.data.data.data;
							}
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				},
				//分页
				changePage(val){
					//获取订单
					this.getOrder(this.activeName,val)
				},
				//删除订单
				delOrder(item){
					var ord_id = [this.PublicJs.Encrypt(item.ord_id)]
					this.$http.post(this.PublicJs.publicPath + "/api/student.Order/del.html", {
						ord_id:ord_id,
						token:window.sessionStorage.getItem('token')
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							//获取订单
							this.getOrder(this.activeName,1)
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				},
				//多选
				choseCheck(item){
					item.check = !item.check;
				},
				//批量删除
				batchDelete(){
					var ord_id = []
					if(this.activeName == "0"){
						for(var i=0;i<this.allList.length;i++){
							if(this.allList[i].check){
								ord_id.push(this.PublicJs.Encrypt(this.allList[i].ord_id))
							}
						}
					}else if(this.activeName == "1"){
						for(var i=0;i<this.incompleteList.length;i++){
							if(this.incompleteList[i].check){
								ord_id.push(this.PublicJs.Encrypt(this.incompleteList[i].ord_id))
							}
						}
					}else if(this.activeName == "2"){
						for(var i=0;i<this.cancelledList.length;i++){
							if(this.cancelledList[i].check){
								ord_id.push(this.PublicJs.Encrypt(this.cancelledList[i].ord_id))
							}
						}
					}else if(this.activeName == "3"){
						for(var i=0;i<this.completedList.length;i++){
							if(this.completedList[i].check){
								ord_id.push(this.PublicJs.Encrypt(this.completedList[i].ord_id))
							}
						}
					}
					if(ord_id.length){
						this.$http.post(this.PublicJs.publicPath + "/api/student.Order/del.html", {
							ord_id:ord_id,
							token:window.sessionStorage.getItem('token')
						}, {
							emulateJSON: true
						}).then(response => {
							if(response.data.code == 1){
								//获取订单
								this.getOrder(this.activeName,1)
							}else{
								this.$message({
									message: response.data.msg,
									type: 'warning',
									showClose: true
								});
							}
						}, response => {
							if(response.status == 401){
								var that = this;
								this.$message({
									message: "您的账号在别处登录，请重新登录",
									type: 'warning',
									showClose: true
								});
								window.sessionStorage.removeItem('user_id')
								window.sessionStorage.removeItem('token')
								window.sessionStorage.removeItem('userinfo')
								setTimeout(function(){
									that.$router.go(0)
								},2000); 
								
							}
						});
					}else{
						this.$message({
							message: "请先选择要删除的订单",
							type: 'warning',
							showClose: true
						});
					}
				},
				//跳转支付
				gotoPay(item){
					this.$router.push({
						path: "/payment",
						query: {
							order_id:this.PublicJs.Encrypt(item.ord_id),
							pay_type:this.PublicJs.Encrypt(item.ord_paytype),
							totalPrice:this.PublicJs.Encrypt(item.ord_price),
						}
					});
				},
				//切换选项卡
				tabClick(){
					//获取订单
					this.getOrder(this.activeName,1)
				},
				//取消订单
				cancelOrder(item){
					this.$http.post(this.PublicJs.publicPath + "/api/student.Order/cancel.html", {
						ids:this.PublicJs.Encrypt(item.ord_id),
						token:window.sessionStorage.getItem('token')
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							//获取订单
							this.getOrder(this.activeName,1)
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				},
				//退款申请弹窗
				showfefund(item){
					this.fefundVisible = true;
					//获取退款原因
					this.getReason();
					this.cuurOrder = item;
				},
				//获取退款原因
				getReason(){
					this.$http.post(this.PublicJs.publicPath + "/api/student.index/dictionary.html", {
						dic_type:"reason",
						token:window.sessionStorage.getItem('token'),
						page:1,
						limit:999
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.refundList = response.data.data.data;
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				},
				//退款原因改变
				changeRefund(){
					if(this.fefundForm.orde_refund_dcid){
						this.orde_refund_dcid = false;
					}else{
						this.orde_refund_dcid = true
					}
				},
				//退款提交
				fefundOk(){
					if(this.fefundForm.orde_refund_dcid){
						this.$http.post(this.PublicJs.publicPath + "/api/student.Order/refundOrBack.html", {
							fu_remark:this.fefundForm.fu_remark,
							action:"add",
							ids:this.PublicJs.Encrypt(this.cuurOrder.orde_id),
							orde_refund_dcid:this.fefundForm.orde_refund_dcid,
							token:window.sessionStorage.getItem('token'),
						}, {
							emulateJSON: true
						}).then(response => {
							if(response.data.code == 1){
								this.fefundVisible = false;
								//获取订单
								this.getOrder(this.activeName,1)
								this.$message({
									message: response.data.msg,
									type: 'success',
									showClose: true
								});
							}else{
								this.$message({
									message: response.data.msg,
									type: 'warning',
									showClose: true
								});
							}
						}, response => {
							if(response.status == 401){
								var that = this;
								this.$message({
									message: "您的账号在别处登录，请重新登录",
									type: 'warning',
									showClose: true
								});
								window.sessionStorage.removeItem('user_id')
								window.sessionStorage.removeItem('token')
								window.sessionStorage.removeItem('userinfo')
								setTimeout(function(){
									that.$router.go(0)
								},2000); 
								
							}
						});
					}else{
						this.orde_refund_dcid = true
					}
				},
				//取消退款
				cancelfefund(item){
					this.$http.post(this.PublicJs.publicPath + "/api/student.Order/refundOrBack.html", {
						fu_remark:"",
						action:"cannel ",
						ids:this.PublicJs.Encrypt(item.orde_id),
						orde_refund_dcid:"",
						token:window.sessionStorage.getItem('token'),
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							//获取订单
							this.getOrder(this.activeName,1)
							this.$message({
								message: response.data.msg,
								type: 'success',
								showClose: true
							});
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}
			},
			created() {
				Bus.$emit('bus_menuChose', "order");
				//获取订单
				this.getOrder(this.activeName,1)
				console.log(this.PublicJs.Decrypt("kTDGxewB+M3J0cD9skPIOQ=="))
			}
		}
	</script>
</script>

<style scoped="scoped">
	.order,.el-tabs,.el-tab-pane {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.el-tabs{
		padding-top: 60px;
	}
	.batchDelete{
		background: linear-gradient(0deg,#4c6df1 0%, #5e7bf2 100%);
		border-radius: 17px;
		box-shadow: 1px 3px 7px 0px rgba(93,123,241,0.17); 
		width: 76px;
		height: 34px;
		text-align: center;
		line-height: 34px;
		color: #FFF;
		position: absolute;
		right: 28px;
		top: 12px;
		font-size: 14px;
		cursor: pointer;
	}
	.batchDelete:hover{
		opacity: 0.9;
	}
	.refundSelect{
		width: 100%;
	}
	.errorText{
		position: absolute;
		line-height: 12px;
		font-size: 12px;
		color: #f56c6c;
		left: 0;
		bottom: -18px;
	}
</style>
<style>
	.order .el-tabs__content{
		width: 100%;
		height: 100%;
	}
	.order .el-tabs__header{
		margin: 0;
		width: 100%;
		position: absolute;
		top: 0;
		left:0;
	}
	.order .el-tabs__item{
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		color: #333;
		text-align: center;
		outline: none;
	}
	.order .el-tabs__nav-wrap::after{
		height: 1px;
		background-color: #E6E7EB;
	}
	.order .el-tabs__active-bar{
		background-color:#5E7BF2;
		height: 3px;
	}
	.order .el-tabs__item.is-active{
		font-weight: bold;
	}
	.order .el-tabs--top .el-tabs__item.is-top:nth-child(2){
		padding-left: 20px;
	}
	.fefundDialog .el-dialog__header {
	    background-color: #4D6EF2;
	    padding: 0 20px;
	    height: 50px;
	    line-height: 50px;
	}
	.fefundDialog .el-dialog__title {
	    color: #fff;
	    font-size: 16px;
	}
	.fefundDialog .el-dialog__headerbtn {
	    top: 14px;
	    font-size: 20px;
	}
	.fefundDialog .el-dialog__headerbtn .el-dialog__close {
	    color: #FFF;
	}
	.fefundDialog .el-dialog__body{
		padding: 30px 20px 0;
	}
	.fu_remark textarea{
		height: 100px;
		resize: none;
	}
	.fefundDialog .el-form-item__content{
		position: relative;
	}
</style>
