<template>
	<div class="orderList_box">
		<div class="orderList" v-if="data.length">
			<div class="orderItem" v-for="(item,index) in data" :key="index">
				<div class="orderHeader">
					<span class="orderCheck" v-if="item.ord_status == 2" :class="{active:item.check}" @click="choseCheck(item)"><i class="el-icon-check"></i></span>
					<span class="orderTime">{{item.createtime}}</span>
					<span class="orderNumber">订单号：{{item.ord_ordersn}}</span>
					<div v-if="item.ord_status == 2" class="orderDelete_box">
						<el-popconfirm
							title="确定要删除该订单吗？"
							@confirm="delOrder(item)"
						>
							<i class="el-icon-delete orderDelete" slot="reference"></i>
						</el-popconfirm>
					</div>					
				</div>
				<div class="orderBottom">
					<div v-for="(items,index) in item.order_detail" :key="index" class="orderInfo" :class="{noBorder: item.order_detail.length == (index+1)}">
						<div class="orderImg"><img :src="items.orde_type == 2 ? items.zt_thumb : items.cou_thumb" /></div>
						<div class="courseInfo">
							<p class="courseName">{{items.orde_type == 2 ? items.zt_name :items.cou_name}}</p>
							<p v-if="items.orde_type == 2">
								总课程：{{items.zt_count}}门，共{{items.zt_sumtime}}学时
							</p>
							<p class="courseTY" v-else>
								<span class="courseType">{{items.dic_name}}</span>
								<span class="courseYear">【{{items.cou_year}}】</span>
							</p>
						</div>
						<div class="orderPrice">价格：{{items.orde_type == 2 ? item.ord_price : items.cou_price}}</div>
						<div v-if="item.ord_type == 0 || item.ord_type == 2">
							<div class="orderType isFefund" v-if="items.orde_status == 3 && items.re_code == '0'">
								<p>{{items.orde_status_text}}</p>
								<p class="fefund" @click="showfefund(items)">退款</p>
							</div>
							<div class="orderType isFefund" v-else-if="items.orde_status == 4">
								<p>{{items.orde_status_text}}</p>
								<el-popconfirm
									title="确定要取消退款吗？"
									@confirm="cancelfefund(items)"
								>
									<p class="fefund" slot="reference">取消退款</p>
								</el-popconfirm>
							</div>
							<div class="orderType" v-else-if="items.orde_status == 5">
								<el-popover
								    placement="top"
								    width="200"
								    trigger="hover"
								    content="已成功申请退款，请耐心等待">
								    <span slot="reference">{{items.orde_status_text}}</span>
								</el-popover>
							</div>
							<div class="orderType isFefund" v-else-if="items.orde_status == 6 && items.re_code == '3'">
								<el-popover
								    placement="top"
								    width="200"
								    trigger="hover"
								    :content='"拒绝退款原因："+items.orde_rea_remark'>
								    <span slot="reference">{{items.orde_status_text}}</span>
								</el-popover>
								<p class="fefund" @click="showfefund(items)">退款</p>
							</div>
						</div>
						<div class="orderType" v-else>
							<p>{{items.orde_status_text}}</p>
						</div>
					</div>
					<div class="rightStatus" :class="{leftBorder:item.order_detail.length > 1}">
						<div v-if="item.ord_status == 1" class="orderState">
							<p class="Topay" @click="gotoPay(item)">去支付</p>
							<el-popconfirm
								title="确定要取消该订单吗？"
								@confirm="cancelOrder(item)"
							>
								<p class="cancelOrder" slot="reference">取消订单</p>
							</el-popconfirm>
						</div>
						<div v-else-if="item.ord_status == 2" class="orderStateT">
							已取消
						</div>
						<div v-else-if="item.ord_status == 3" class="orderStateT">
							已完成
						</div>
					</div>
				</div>
			</div>
			<div class="orderPaginat" v-if="total > pageSize">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  @current-change="changPage">
				</el-pagination>
			</div>
		</div>
		<div class="noData" v-else>
			<img src="@/views/images/noData.png" />
			<div class="noDataTips">
				<span>暂无数据</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			data:{
				type: Array
			},
			total:{
				type: Number
			},
			pageSize:{
				type: Number
			},
		},
		data() {
			return {
				checkList:[],
			}
		},
		methods:{
			//分页跳转
			changPage(val){
				this.$emit('order-chang-page', val);
			},
			//删除订单
			delOrder(item){
				this.$emit('del-order', item);
			},
			//多选
			choseCheck(item){
				this.$emit('chose-check', item);
			},
			//跳转支付
			gotoPay(item){
				this.$emit('goto-pay', item);
			},
			//取消订单
			cancelOrder(item){
				this.$emit('cancel-order', item);
			},
			//退款申请
			showfefund(item){
				this.$emit('show-fefund', item);
			},
			//取消退款
			cancelfefund(item){
				this.$emit('cancel-fefund', item);
			}
		}
	}
</script>

<style scoped="scoped">
	.orderList_box{
		position: relative;
		width: 100%;
		height: 100%;
	}
	.noData{
		text-align: center;
		width: 100%;
		height: 236px;
		margin-top: 153px;
	}
	.noDataTips{
		font-size: 16px;
		color: #333;
		margin-top: 25px;
	}
	.orderList{
		padding: 0 28px 20px 28px;
	}
	.orderItem{
		width: 100%;
		border: 1px solid #F6F6F6;
		margin-top: 20px;
	}
	.orderHeader{
		width: 100%;
		height: 36px;
		line-height: 36px;
		font-size: 0;
		background-color: #F7F8FE;
		padding: 0 18px;
	}
	.orderHeader span{
		vertical-align: middle;
		display: inline-block;
	}
	.orderCheck{
		width: 15px;
		height: 15px;
		border: 1px solid #999999;
		cursor: pointer;
		margin-right: 14px;
	}
	.orderCheck i{
		font-size: 13px;
		vertical-align: top;
		color: #F7F8FE;
	}
	.orderCheck.active{
		border: 1px solid #5e7bf2;
	}
	.orderCheck.active i{
		color: #5e7bf2;
	}
	.orderTime,.orderNumber{
		font-size: 14px;
		color: #999;
		margin-right: 55px;
	}
	.orderDelete_box{
		float: right;
	}
	.orderDelete{
		font-size: 18px;
		color: #999;
		margin-top: 7px;
		cursor: pointer;
	}
	.orderInfo{
		width: 100%;
		height: 111px;
		border-bottom: 1px solid #EEEEEE;
	}
	.orderInfo::after{
		content: "";
		display: block;
		clear: both;
	}
	.orderInfo.noBorder{
		border-bottom: none;
	}
	.orderInfo div{
		float: left;
		height: 100%;
		color: #333;
		font-size: 14px;
	}
	.orderImg{
		width: 154px;
		padding: 19px 15px 19px 18px;
	}
	.orderImg img{
		width: 100%;
		height: 100%;
		border-radius: 4px;
	}
	.courseInfo{
		width: 226px;
	}
	.courseName{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		padding-top: 18px;
		line-height: 21px;
		margin-bottom: 10px;
	}
	.courseType{
		display: inline-block;
		width: 46px;
		height: 22px;
		opacity: 1;
		background: #ffac3d;
		border-radius: 4px;
		font-size: 12px;
		color: #fff;
		text-align: center;
		line-height: 22px;
		margin-right: 13px;
	}
	.orderBottom{
		position: relative;
	}
	.orderInfo .orderPrice{
		width: 172px;
		padding-left: 44px;
		line-height: 111px;
		font-size: 12px;
	}
	.orderInfo .orderType{
		width: 159px;
		line-height: 111px;
		font-size: 12px;
		text-align: center;
	}
	.orderInfo .orderType.isFefund{
		line-height: 26px;
		padding-top: 28px;
	}
	.fefund{
		color: #ffac3d;
		cursor: pointer;
		outline: none;
	}
	.fefund:hover{
		color: #f19317;
	}
	.rightStatus{
		position: absolute;
		width: 131px;
		height: 100%;
		top: 0;
		right: 0;
		background-color: #FFF;
	}
	.rightStatus.leftBorder{
		border-left: 1px solid #EEEEEE;
	}
	.orderStateT{
		width: 131px;
		line-height: 111px;
		text-align: center;
	}
	.orderState{
		width: 131px;
		text-align: center;
		padding-top: 25px;
		height: 111px;
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -55px;
	}
	.orderState p{
		display: inline-block;
		width: 84px;
		height: 34px;
		line-height: 34px;
		margin-bottom: 5px;
		cursor: pointer;
	}
	.orderState p.Topay{
		background: linear-gradient(0deg,rgba(77,110,242,0.90) 0%, rgba(77,110,242,0.70) 100%);
		border-radius: 17px;
		color: #FFF;
	}
	.orderState p.Topay:hover{
		background: linear-gradient(0deg, rgba(77,110,242,0.70) 100%,rgba(77,110,242,0.90) 0%);
	}
	.orderState p.cancelOrder:hover{
		color: #999;
	}
	.orderPaginat{
		text-align: center;
		padding: 20px 0;
	}
</style>
